import { Component, OnInit, ViewChild } from "@angular/core";
import { ColDef } from "ag-grid-community";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { AgGridAngular } from "ag-grid-angular";
import { CustomRichTextService } from "src/app/shared/generated/api/custom-rich-text.service";
import { CustomRichTextDto } from "src/app/shared/generated/model/custom-rich-text-dto";
import { AgGridHelper } from "src/app/shared/helpers/ag-grid-helper";
import { RiparisGridComponent } from "src/app/shared/components/riparis-grid/riparis-grid.component";
import { AlertDisplayComponent } from "src/app/shared/components/alert-display/alert-display.component";
import { PageHeaderComponent } from "src/app/shared/components/common/page-header/page-header.component";
import { UtilityFunctionsService } from "src/app/shared/services/utility-functions.service";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "riparis-field-definition-list",
    templateUrl: "./field-definition-list.component.html",
    styleUrls: ["./field-definition-list.component.scss"],
    standalone: true,
    imports: [PageHeaderComponent, AlertDisplayComponent, RiparisGridComponent],
})
export class FieldDefinitionListComponent implements OnInit {
    @ViewChild("fieldDefinitionsGrid") fieldDefinitionsGrid: AgGridAngular;

    public fieldDefinitions: CustomRichTextDto[];
    public richTextTypeID: number = CustomRichTextTypeEnum.LabelsAndDefinitionsList;
    public agGridOverlay: string = AgGridHelper.gridSpinnerOverlay;
    public columnDefs: ColDef[];
    public isReadonly: boolean = true;

    constructor(private route: ActivatedRoute, private customRichTextService: CustomRichTextService, private utilityFunctionService: UtilityFunctionsService) {}

    ngOnInit() {
        this.customRichTextService.customRichTextsFieldDefinitionsGet().subscribe((fieldDefinitions) => {
            this.fieldDefinitions = fieldDefinitions;
        });

        this.isReadonly = this.route.snapshot.data.isReadonly;
        this.createColumnDefs();
    }

    private createColumnDefs() {
        this.columnDefs = [
            this.utilityFunctionService.createLinkColumnDef("Label", "CustomRichTextType.CustomRichTextTypeDisplayName", "CustomRichTextType.CustomRichTextTypeID", {
                InRouterLink: "./",
                Width: 100,
                Hide: this.isReadonly,
            }),
            {
                headerName: "Label",
                field: "CustomRichTextType.CustomRichTextTypeDisplayName",
                hide: !this.isReadonly,
            },
            { headerName: "Definition", cellRenderer: (params) => params.data.CustomRichTextContent },
        ];
    }
}
