import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IconComponent } from "../icon/icon.component";

@Component({
    selector: "filter-tag",
    standalone: true,
    imports: [IconComponent],
    templateUrl: "./filter-tag.component.html",
    styleUrl: "./filter-tag.component.scss",
})
export class FilterTagComponent {
    @Input() tag: string;
    @Output() tagClicked = new EventEmitter();
    @Output() tagRemoved = new EventEmitter();

    public onTagClicked() {
        this.tagClicked.emit();
    }

    public onTagRemoved() {
        this.tagRemoved.emit();
    }
}
