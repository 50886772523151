<div class="filter-popup" *ngIf="filterModel">
    <div class="filter-header flex-between">
        <h2 class="filter-title">
            <icon *ngIf="titleIcon" class="title-icon" [icon]="titleIcon"></icon>
            {{ this.filterModel.title }}
        </h2>

        <button type="button" class="close" (click)="closePopup()">
            <icon icon="Times"></icon>
        </button>
    </div>

    <div class="filter-body">
        <form [formGroup]="tempFormGroup">
            <div class="field" *ngFor="let field of filterModel.fields">
                <div class="field-header">
                    <field-definition
                        *ngIf="field.headerFieldDefinitionType"
                        [fieldDefinitionType]="field.headerFieldDefinitionType"
                        [inline]="false"
                        [labelOverride]="field.labelOverride">
                    </field-definition>
                    <span *ngIf="!field.headerFieldDefinitionType">{{ field.header }}</span>
                </div>
                <div [ngSwitch]="field.type">
                    <span *ngSwitchCase="'Checklist'">
                        <ul>
                            <li *ngFor="let option of field.options">
                                <input type="checkbox" [id]="option.formControlName" [name]="option.formControlName" [formControlName]="option.formControlName" />
                                <label [for]="option.formControlName">{{ option.name }}</label>
                            </li>
                        </ul>
                    </span>
                    <span *ngSwitchCase="'Range'">
                        <div class="custom-slider">
                            <ngx-slider [options]="field.rangeSliderOptions" [formControlName]="field.formControlName"></ngx-slider>
                        </div>
                    </span>
                </div>
            </div>
        </form>
    </div>

    <div class="filter-footer flex-between">
        <button class="btn reset" (click)="resetFormValuesToDefault()">Reset Filters</button>
        <button class="btn btn-primary-outline btn-sm apply" (click)="applyFilters()">Apply</button>
    </div>
</div>
