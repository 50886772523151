<section class="content-section">
    <form [formGroup]="formGroup">
        <div class="grid-12">
            <div class="g-col-3">
                <div class="filter-wrapper">
                    <div class="filter-section">
                        <div class="filter-section-header">
                            <h3><icon icon="Location"></icon> Area of Interest</h3>
                        </div>

                        <div class="filter-section-body">
                            <div class="dropdown-container">
                                <ng-template #optionTemplate let-item="item" let-config="config">
                                    <div class="switcher">
                                        <div class="switcher-title">
                                            <span class="switcher-name">{{ item.EntityName }}</span>
                                            <span class="switcher-id" *ngIf="isWatershed()">
                                                (WRIA
                                                {{ item.EntityID }})</span
                                            >
                                        </div>
                                    </div>
                                </ng-template>

                                <ng-template #selectedTemplate let-item="item" let-config="config"> </ng-template>

                                <ng-template #dropdownButton>
                                    <div class="switch-reach-explorer-entity">
                                        <label for="" class="primary-label">
                                            <field-definition
                                                *ngIf="UserFilterObjectTypeEnum[userFilterObjectType] === 'Watershed'; else noWatershed"
                                                [fieldDefinitionType]="'Watershed'"
                                                [inline]="false"></field-definition>

                                            <ng-template #noWatershed>
                                                {{ UserFilterObjectTypeEnum[userFilterObjectType] }}
                                            </ng-template>
                                        </label>

                                        <div class="switch-reach-explorer-entity__dropdown">
                                            <div class="current-wria" *ngIf="currentReachExplorerEntity">
                                                {{ currentReachExplorerEntity.EntityName }}
                                                <span class="wria-id" *ngIf="isWatershed()">
                                                    (WRIA
                                                    {{ currentReachExplorerEntity.EntityID }})</span
                                                >
                                            </div>

                                            <icon icon="AngleDown"></icon>
                                        </div>
                                    </div>
                                </ng-template>
                                <ngx-select-dropdown
                                    [optionItemTemplate]="optionTemplate"
                                    [selectedItemTemplate]="selectedTemplate"
                                    [dropdownButtonTemplate]="dropdownButton"
                                    id="currentReachExplorerEntity"
                                    name="currentReachExplorerEntity"
                                    [(ngModel)]="currentReachExplorerEntity"
                                    [ngModelOptions]="{ standalone: true }"
                                    [config]="reachExplorerEntityDropdownConfig"
                                    [options]="reachExplorerEntities"
                                    (change)="changedReachExplorerEntity()">
                                </ngx-select-dropdown>
                            </div>

                            <div class="inline-filter">
                                <label class="primary-label">
                                    <field-definition fieldDefinitionType="Zones" labelOverride="Zone" [inline]="false"></field-definition>
                                </label>
                                <div class="checkbox-group">
                                    <ul>
                                        <li>
                                            <input type="checkbox" formControlName="FilterToRMZ" id="FilterToRMZ" (change)="filter()" />
                                            <label for="FilterToRMZ">Riparian Management Zone (RMZ)</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" formControlName="FilterToEOW" id="FilterToEOW" (change)="filter()" />
                                            <label for="FilterToEOW">Extent of Observed Water (EOW)</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" formControlName="FilterToFlood" id="FilterToFlood" (change)="filter()" />
                                            <label for="FilterToFlood">Floodplain</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="filter-section">
                        <div class="filter-section-header">
                            <h3><icon icon="Filter"></icon> Filters</h3>
                            <button class="reset-button" (click)="resetAndFilter()">Reset filters</button>
                        </div>

                        <div class="filter-section-body" #filterBodySearchCriteria>
                            <popup-filter
                                *ngIf="showPopupFilter && currentFilterModel"
                                [filterModel]="currentFilterModel"
                                [formGroup]="formGroup"
                                [formGroupDefaultValues]="formGroupDefaultValues"
                                (filtersApplied)="applyFilters()"
                                (close)="closeFilterPopup()">
                            </popup-filter>

                            <div class="filter-section-item">
                                <div class="filter">
                                    <div class="filter-icon"><icon icon="Salmon"></icon></div>
                                    Species & Ecological Data
                                    <button class="expand-button" (click)="launchPopupFilter(speciesAndEcologicalDataFilterModel)">+</button>
                                </div>

                                <filter-tag
                                    *ngFor="let tag of speciesAndEcologicalDataFilterModel.tags; let i = index"
                                    [tag]="tag.tagDisplay"
                                    (tagClicked)="launchPopupFilter(speciesAndEcologicalDataFilterModel)"
                                    (tagRemoved)="onFilterTagRemoved(tag, speciesAndEcologicalDataFilterModel, i)">
                                </filter-tag>
                            </div>

                            <div class="filter-section-item">
                                <div class="filter">
                                    <div class="filter-icon"><icon icon="LandCover"></icon></div>
                                    Environmental & Riparian Characteristics
                                    <button class="expand-button" (click)="launchPopupFilter(environmentalAndRiparianCharacteristicsFilterModel)">+</button>
                                </div>

                                <filter-tag
                                    *ngFor="let tag of environmentalAndRiparianCharacteristicsFilterModel.tags; let i = index"
                                    [tag]="tag.tagDisplay"
                                    (tagClicked)="launchPopupFilter(environmentalAndRiparianCharacteristicsFilterModel)"
                                    (tagRemoved)="onFilterTagRemoved(tag, environmentalAndRiparianCharacteristicsFilterModel, i)">
                                </filter-tag>
                            </div>

                            <div class="filter-section-item">
                                <div class="filter">
                                    <div class="filter-icon"><icon icon="Barrier"></icon></div>
                                    Fish Passage Barriers & Access
                                    <button class="expand-button" (click)="launchPopupFilter(fishPassageBarriersAndAccessFilterModel)">+</button>
                                </div>

                                <filter-tag
                                    *ngFor="let tag of fishPassageBarriersAndAccessFilterModel.tags; let i = index"
                                    [tag]="tag.tagDisplay"
                                    (tagClicked)="launchPopupFilter(fishPassageBarriersAndAccessFilterModel)"
                                    (tagRemoved)="onFilterTagRemoved(tag, fishPassageBarriersAndAccessFilterModel, i)">
                                </filter-tag>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="g-col-9"
                [loadingSpinner]="{
                    isLoading: isLoadingReachExplorerEntities,
                    loadingHeight: 100,
                }">
                <ng-container *ngIf="currentReachExplorerEntity">
                    <div class="page-header">
                        <h1 class="page-title page-title__reachExplorerEntity">
                            <span class="icon-wrapper">
                                <icon icon="MapOpen"></icon>
                            </span>
                            {{ currentReachExplorerEntity.EntityName }}

                            {{ UserFilterObjectTypeEnum[userFilterObjectType] }}
                            <span class="wria-id" *ngIf="userFilterObjectType === UserFilterObjectTypeEnum.Watershed">
                                (WRIA
                                {{ currentReachExplorerEntity.EntityID }})</span
                            >
                        </h1>
                    </div>
                </ng-container>
                <btn-group-radio-input label="Test" [options]="tabs" (change)="setActiveTab($event)" [default]="activeTab" [showIcons]="true"></btn-group-radio-input>

                <riparis-map (onMapLoad)="handleMapReady($event)" mapHeight="800px" [hidden]="activeTab !== 'Map'"> </riparis-map>

                <ng-template #noReaches> There are no reaches in your filter set. </ng-template>

                <div [hidden]="activeTab !== 'LandCover'">
                    <div
                        [loadingSpinner]="{
                            isLoading: isLoadingLandCoverChartData,
                            loadingHeight: 100,
                        }">
                        <h3 class="chartTitle">
                            <field-definition fieldDefinitionType="LandCoverByType" [inline]="false"></field-definition>
                        </h3>
                        <hr />
                        <land-cover-bar-chart *ngIf="landCoverChartData?.length > 0; else noReaches" [chartData]="landCoverChartData"></land-cover-bar-chart>
                    </div>
                    <div
                        class="mt-4"
                        [loadingSpinner]="{
                            isLoading: isLoadingTreeDecreaseAcresChartData,
                            loadingHeight: 100,
                        }">
                        <h3 class="chartTitle">
                            <field-definition fieldDefinitionType="TreeDecreaseAcres" [inline]="false"></field-definition>
                        </h3>
                        <hr />
                        <tree-decrease-acres-stacked-area-chart
                            *ngIf="treeDecreaseAcresChartData?.length > 0; else noReaches"
                            [chartData]="treeDecreaseAcresChartData"></tree-decrease-acres-stacked-area-chart>
                    </div>
                </div>

                <div [hidden]="activeTab !== 'Hydrology'">
                    <div>
                        <h3 class="chartTitle">
                            <field-definition fieldDefinitionType="AreaByReachZone" [inline]="false"></field-definition>
                        </h3>
                        <hr />
                        <zone-area-radial-chart *ngIf="zoneAreaChartData?.length > 0; else noReaches" [chartData]="zoneAreaChartData"></zone-area-radial-chart>
                    </div>
                </div>

                <div [hidden]="activeTab !== 'Salmon'">
                    <div
                        [loadingSpinner]="{
                            isLoading: isLoadingSalmonDistributionChartData,
                            loadingHeight: 100,
                        }">
                        <h3 class="chartTitle">
                            <field-definition fieldDefinitionType="SalmonDistributionBySpecies" [inline]="false"></field-definition>
                        </h3>
                        <hr />
                        <salmon-distribution-bar-chart
                            *ngIf="salmonDistributionChartData?.length > 0; else noReaches"
                            [chartData]="salmonDistributionChartData"></salmon-distribution-bar-chart>
                    </div>
                </div>

                <div [hidden]="activeTab !== 'Administrative'">
                    <div
                        [loadingSpinner]="{
                            isLoading: isLoadingLandUseChartData,
                            loadingHeight: 100,
                        }">
                        <h3 class="chartTitle">
                            <field-definition fieldDefinitionType="AreaByLandUse" [inline]="false"></field-definition>
                        </h3>
                        <hr />
                        <land-use-waffle-chart *ngIf="landUseChartData?.length > 0; else noReaches" [chartData]="landUseChartData"></land-use-waffle-chart>
                    </div>
                </div>

                <div [hidden]="activeTab !== 'Reach'">
                    <div class="search-results mt-4">
                        <div class="grid-12">
                            <div class="g-col-6">
                                <h4 class="search-results-title">
                                    <field-definition
                                        [fieldDefinitionType]="'FilteredRiparianAcres'"
                                        [inline]="false"
                                        [labelOverride]="'Filtered Riparian Acres (% of Total)'"></field-definition
                                    >:
                                    <span class="value pl-1"
                                        >{{ reachSearchResult?.RiparianAcres | number: "1.0-0" }} ({{
                                            (reachSearchResult?.RiparianAcres ?? 0) / currentReachExplorerEntity.RiparianAcres | percent: "1.0-2"
                                        }})</span
                                    >
                                </h4>
                            </div>
                            <div class="g-col-6">
                                <h4 class="search-results-title">
                                    <field-definition
                                        [fieldDefinitionType]="'FilteredStreamMiles'"
                                        [inline]="false"
                                        [labelOverride]="'Filtered Stream Miles (% of Total)'"></field-definition
                                    >:
                                    <span class="value pl-1"
                                        >{{ reachSearchResult?.StreamMiles | number: "1.0-0" }} ({{
                                            (reachSearchResult?.StreamMiles ?? 0) / currentReachExplorerEntity.StreamMiles | percent: "1.0-2"
                                        }})</span
                                    >
                                </h4>
                            </div>
                        </div>
                        <div class="mt-2">
                            <riparis-grid
                                height="600px"
                                [rowData]="reachSearchResult?.Reaches ?? []"
                                [columnDefs]="columnDefs"
                                downloadDisplayName="reaches-shown"
                                [pagination]="true"
                                [sizeColumnsToFitGrid]="true"
                                (gridReady)="onGridReady($event)"
                                rowSelection="single">
                            </riparis-grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>
