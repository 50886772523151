import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { IconComponent } from "../icon/icon.component";
import { CommonModule } from "@angular/common";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FieldDefinitionComponent } from "../field-definition/field-definition.component";
import { NgxSliderModule, Options } from "@angular-slider/ngx-slider";

@Component({
    selector: "popup-filter",
    standalone: true,
    imports: [IconComponent, FieldDefinitionComponent, CommonModule, FormsModule, NgxSliderModule, ReactiveFormsModule],
    templateUrl: "./popup-filter.component.html",
    styleUrl: "./popup-filter.component.scss",
})
export class PopupFilterComponent implements OnInit, OnDestroy {
    @Input() filterModel: PopupFilterModel;
    @Input() formGroup: FormGroup;
    @Input() formGroupDefaultValues: { [formControlName: string]: any };

    @Output() filtersApplied = new EventEmitter();
    @Output() close = new EventEmitter();

    public titleIcon: any;
    public tempFormGroup: FormGroup;

    ngOnInit(): void {
        this.titleIcon = this.filterModel.titleIcon ?? "Filter";
        this.setFormValuesFromFormGroup();
    }

    ngOnDestroy(): void {
        this.tempFormGroup = null;
    }

    public setFormValuesFromFormGroup() {
        this.tempFormGroup = new FormGroup([], {});

        this.filterModel.fields.forEach((field) => {
            if (field.formControlName) {
                this.tempFormGroup.addControl(field.formControlName, new FormControl(this.formGroup.controls[field.formControlName].getRawValue()));
            } else if (field.options?.length > 0) {
                field.options.forEach((option) => {
                    if (option.formControlName) {
                        this.tempFormGroup.addControl(option.formControlName, new FormControl(this.formGroup.controls[option.formControlName].getRawValue()));
                    }
                });
            }
        });
    }

    public resetFormValuesToDefault() {
        Object.keys(this.tempFormGroup.controls).forEach((formControlName) => {
            this.tempFormGroup.controls[formControlName].patchValue(this.formGroupDefaultValues[formControlName]);
        });
    }

    public applyFilters() {
        // set formGroup values from tempFormGroup
        Object.keys(this.tempFormGroup.controls).forEach((formControlName) => {
            this.formGroup.controls[formControlName].patchValue(this.tempFormGroup.controls[formControlName].getRawValue());
        });

        // determine filter tags to display
        const tags = [];
        this.filterModel.fields.forEach((field) => {
            switch (field.type) {
                case "Range":
                    const rangeTag = this.getRangeTag(field);
                    if (rangeTag) {
                        tags.push(rangeTag);
                    }
                    return;

                case "Checklist":
                    const checklistTag = this.getChecklistTag(field);
                    if (checklistTag) {
                        tags.push(checklistTag);
                    }
                    return;
            }
        });
        this.filterModel.tags = tags;

        this.filtersApplied.emit();
    }

    public closePopup() {
        this.close.emit();
    }

    public getRangeTag(field: FilterModelField): FilterTag {
        let tag = null;
        const value = this.formGroup.controls[field.formControlName].getRawValue();
        if (value[0] != field.rangeSliderOptions.floor || value[1] != field.rangeSliderOptions.ceil) {
            tag = { tagDisplay: `${field.header}: ${value[0]}% to ${value[1]}%`, fieldName: field.header } as FilterTag;
        }
        return tag;
    }

    public getChecklistTag(field: FilterModelField): FilterTag {
        let allApplied = true;
        const selectedOptions = [];

        field.options.forEach((option) => {
            const optionChecked = this.formGroup.controls[option.formControlName].getRawValue();
            if (optionChecked) {
                selectedOptions.push(option.name);
            } else {
                allApplied = false;
            }
        });

        let tag = null;
        if (!allApplied) {
            tag = { tagDisplay: `${field.header} (${selectedOptions.length}): ${selectedOptions.join(", ")}`, fieldName: field.header } as FilterTag;
        }

        return tag;
    }
}

export interface PopupFilterModel {
    title: string;
    titleIcon?: any;
    fields: FilterModelField[];
    tags?: FilterTag[];
}

export class FilterTag {
    fieldName: string;
    tagDisplay: string;
}

export class FilterModelField {
    type: "Checklist" | "Multiselect" | "ButtonGroup" | "Range";
    header?: string;
    headerFieldDefinitionType?: string;
    labelOverride?: string;

    // checkbox fields
    options?: FilterModelFieldOption[];

    // range fields
    rangeSliderOptions?: Options;
    formControlName?: string;
}

export class FilterModelFieldOption {
    name: number | string;
    formControlName: string;
}
