<div class="dashboard">
    <aside class="sidebar">
        <ul class="sidebar-nav">
            <li class="sidebar-item">
                <a [routerLink]="['terms-and-definitions']" routerLinkActive="active" [routerLinkActiveOptions]="routerLinkActiveOptions" class="sidebar-link"
                    ><i class="fa fa-list-ul"></i>Terms & Definitions</a
                >
            </li>
            <li class="sidebar-item">
                <a [routerLink]="['users']" routerLinkActive="active" [routerLinkActiveOptions]="routerLinkActiveOptions" class="sidebar-link"><i class="fa fa-user"></i>Users</a>
            </li>
        </ul>
    </aside>

    <main class="main">
        <router-outlet #adminOutlet="outlet"></router-outlet>
        <div *ngIf="!adminOutlet.isActivated">
            <page-header pageTitle="Dashboard"> </page-header>

            <div class="page-body grid-12"></div>
        </div>
    </main>
</div>
