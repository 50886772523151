import { Component, Input } from "@angular/core";
import { default as vegaEmbed, VisualizationSpec } from "vega-embed";
import { LoadingDirective } from "../../../directives/loading.directive";
import { ChartData } from "../ChartData";
import { DecimalPipe, NgForOf, NgIf, PercentPipe } from "@angular/common";
import { SumPipe } from "src/app/shared/pipes/sum.pipe";
import { DefaultOrderKeyvaluePipe } from "src/app/shared/pipes/default-order-key-value.pipe";

@Component({
    selector: "tree-decrease-acres-stacked-area-chart",
    standalone: true,
    imports: [LoadingDirective, DefaultOrderKeyvaluePipe, NgIf, NgForOf, PercentPipe, DecimalPipe, SumPipe],
    templateUrl: "./tree-decrease-acres-stacked-area-chart.component.html",
    styleUrl: "./tree-decrease-acres-stacked-area-chart.component.scss",
})
export class TreeDecreaseAcresStackedAreaChartComponent {
    @Input() chartData: ChartData[];
    @Input() chartWidth: number = 600;
    @Input() chartHeight: number = 400;
    @Input() isLoading: boolean = false;

    public chartID: string = "treeDecreaseAcresChart";
    public colorRange: Map<string, string> = new Map([
        ["Forestry", "#b1c8aa"],
        ["General Tree Removal", "#85a277"],
        ["Other Human Causes", "#5a6b51"],
        ["Natural Causes", "#fc9e4f"],
    ]);

    public getTotalValueForGroup(groupName: string): number {
        return this.chartData
            .filter((x) => x.Group === groupName)
            .map((x) => x.YValue)
            .reduce((a, b) => a + b);
    }

    public getTotalPercentageForGroup(groupName: string): number {
        return this.getTotalValueForGroup(groupName) / this.chartData.map((x) => x.YValue).reduce((a, b) => a + b);
    }

    ngOnChanges() {
        const vegaSpec = {
            $schema: "https://vega.github.io/schema/vega-lite/v5.json",
            width: "container",
            height: this.chartHeight,
            autosize: {
                type: "fit-x",
                resize: true,
            },
            config: {
                view: { stroke: null },
                axisY: {
                    labelFont: "Arial",
                    labelFontSize: 16,
                    labelFontWeight: "bold",
                    labelPadding: 10,
                    offset: 2,
                    ticks: false,
                    domainColor: "#dcdcdc",
                },
                axisX: {
                    labelFont: "Arial",
                    labelFontSize: 14,
                    labelFontWeight: "bold",
                    titleFontSize: 14,
                    titleAnchor: "start",
                    titleBaseline: "line-bottom",
                    titleAlign: "right",
                    titlePadding: 0,
                    titleX: -10,
                    titleOpacity: 0.5,
                    labelOpacity: 0.5,
                    ticks: false,
                    domain: false,
                },
                legend: {
                    disable: true,
                },
            },
            data: {
                name: "table",
                values: this.chartData,
            },
            mark: "area",
            encoding: {
                y: { field: "YValue", title: "Tree Decrease (acres)", type: "quantitative" },
                x: { field: "XValue", title: "Year", type: "ordinal" },
                color: {
                    // sort: "descending",
                    // sort: "SortOrder",
                    field: "Group",
                    title: "",
                    type: "nominal",
                    scale: {
                        domain: [...this.colorRange.keys()],
                        range: [...this.colorRange.values()],
                    },
                },
                order: { field: "SortOrder" },
                opacity: { value: 1 },
                tooltip: [
                    { Field: "XValue", Title: "Year", Type: "ordinal" },
                    { Field: "Group", Title: "Change Agent", Type: "ordinal" },
                    { Field: "YValue", Title: "Acres Decreased", Type: "quantitative", Format: ",.1f" },
                ].map((x) => {
                    return { field: x.Field, title: x.Title, type: x.Type, format: x.Format };
                }),
            },
        } as VisualizationSpec;
        console.log(vegaSpec);
        vegaEmbed(`#${this.chartID}`, vegaSpec, { renderer: "svg" }).then((res) => {});
    }
}
