import { Component } from "@angular/core";
import { PageHeaderComponent } from "src/app/shared/components/common/page-header/page-header.component";
import { CustomRichTextComponent } from "src/app/shared/components/custom-rich-text/custom-rich-text.component";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";

@Component({
    selector: "data-sources",
    standalone: true,
    imports: [PageHeaderComponent, CustomRichTextComponent],
    templateUrl: "./data-sources.component.html",
    styleUrl: "./data-sources.component.scss",
})
export class DataSourcesComponent {
    public customRichTextTypeID = CustomRichTextTypeEnum.DataSourcesPage;
}
