<header class="site-header">
    <div *ngIf="!hasManageMenu()">
        <div *ngIf="showTestingWarning()" class="qa-warning">
            <span class="fa fa-warning"></span>
            {{ testingWarningText() }}
        </div>
    </div>

    <nav class="admin-nav" *ngIf="hasManageMenu()">
        <div class="admin-nav__wrapper" #mobileAdminToggle>
            <ul class="admin-nav__list">
                <button class="admin-nav__close" type="button" [dropdownToggleClose]="AdminMenuToggleButton">
                    <i class="fas fa-times-circle"></i>
                </button>

                <li class="admin-nav__item manage">
                    <a routerLink="/admin" class="admin-nav__link" role="button" routerLinkActive="active">
                        <icon icon="Manage"></icon>
                        <span class="admin-nav__link-label">Manager Tools</span>
                    </a>
                </li>

                <li *ngIf="showTestingWarning()" class="qa-warning-admin">
                    <span class="fa fa-warning"></span>
                    {{ testingWarningText() }}
                </li>
            </ul>
        </div>

        <button
            class="admin-nav__toggle"
            [dropdownToggle]="mobileAdminToggle"
            #AdminMenuToggleButton="dropdownToggleName"
            type="button"
            aria-controls="hamburger-btn"
            aria-expanded="false">
            <svg width="24" height="6" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 2C12 1.46957 12.2107 0.96086 12.5858 0.585787C12.9609 0.210714 13.4696 0 14 0C14.5304 0 15.0391 0.210714 15.4142 0.585787C15.7893 0.96086 16 1.46957 16 2C16 2.53043 15.7893 3.03914 15.4142 3.41421C15.0391 3.78929 14.5304 4 14 4C13.4696 4 12.9609 3.78929 12.5858 3.41421C12.2107 3.03914 12 2.53043 12 2ZM6 2C6 1.46957 6.21071 0.96086 6.58579 0.585787C6.96086 0.210714 7.46957 0 8 0C8.53043 0 9.03914 0.210714 9.41421 0.585787C9.78929 0.96086 10 1.46957 10 2C10 2.53043 9.78929 3.03914 9.41421 3.41421C9.03914 3.78929 8.53043 4 8 4C7.46957 4 6.96086 3.78929 6.58579 3.41421C6.21071 3.03914 6 2.53043 6 2ZM0 2C0 1.46957 0.210714 0.96086 0.585786 0.585787C0.960859 0.210714 1.46957 0 2 0C2.53043 0 3.03914 0.210714 3.41421 0.585787C3.78929 0.96086 4 1.46957 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4C1.46957 4 0.960859 3.78929 0.585786 3.41421C0.210714 3.03914 0 2.53043 0 2Z"
                    fill="white" />
            </svg>
        </button>
    </nav>
    <div class="site-nav__container">
        <nav class="site-nav">
            <a href="/" class="site-logo">
                <icon icon="Logo"></icon>
                Riparian Data Engine
            </a>

            <div class="site-nav__wrapper" #mobileMenuToggle>
                <button class="site-nav__close" type="button" [dropdownToggleClose]="mobileMenuToggleButton">
                    <i class="fas fa-times-circle"></i>
                </button>

                <a href="/" class="site-logo-mobile">
                    <icon icon="Logo"></icon>
                </a>

                <ul class="site-nav__links site-nav__main">
                    <li class="nav-item dropdown" routerLinkActive="active">
                        <a
                            href="javascript:void(0);"
                            [dropdownToggle]="learnMoreToggle"
                            class="nav-link dropdown-toggle"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <icon icon="Info"></icon>
                            <span class="nav-link__label">
                                Learn More
                                <icon icon="AngleDown"></icon>
                            </span>
                        </a>

                        <div #learnMoreToggle class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <div class="dropdown-divider"></div>
                            <a routerLink="/about" class="dropdown-item">About the Riparian Data Engine</a>
                            <a routerLink="/data-sources" class="dropdown-item">Data Sources</a>
                            <a routerLink="/terms-and-definitions" class="dropdown-item">Terms and Definitions</a>
                        </div>
                    </li>
                    <ng-container class="ng-container" *ngIf="currentUser$ | async as currentUser">
                        <li
                            class="nav-item dropdown"
                            routerLinkActive="active"
                            *withRolePermission="{
                                currentUser: currentUser,
                                permission: PermissionEnum.WRIARights,
                                rights: RightsEnum.Read,
                            }">
                            <a
                                href="javascript:void(0);"
                                [dropdownToggle]="mapExplorerToggle"
                                class="nav-link dropdown-toggle"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                                <icon icon="MapOpen"></icon>
                                <span class="nav-link__label">Map Explorer <icon icon="AngleDown"></icon></span>
                            </a>

                            <div #mapExplorerToggle class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <div class="dropdown-divider"></div>
                                <a routerLink="/wrias" class="dropdown-item">Watershed Map</a>
                                <a routerLink="/counties" class="dropdown-item">County Map</a>
                            </div>
                        </li>
                    </ng-container>
                </ul>

                <ul class="site-nav__links site-nav__utilities" #testRef>
                    <li class="nav-item" routerLinkActive="active" *ngIf="!isAuthenticated()">
                        <a href="javascript:void(0);" (click)="login()" routerLinkActive="active" class="nav-link"> <icon icon="User"></icon>Sign In </a>
                    </li>

                    <li class="nav-item dropdown welcomeUser" routerLinkActive="active" *ngIf="isAuthenticated()">
                        <a
                            href="javascript:void(0);"
                            [dropdownToggle]="helpToggle"
                            class="nav-link dropdown-toggle"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <icon icon="User"></icon>
                            <span class="username nav-link__label"
                                >{{ isCurrentUserBeingImpersonated() ? "Impersonating" : "Hi, " }}
                                {{ getUserName() }}
                                <icon icon="AngleDown"></icon>
                            </span>
                        </a>

                        <div #helpToggle class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                            <a href="javascript:void(0);" (click)="logout()" class="dropdown-item">{{ isCurrentUserBeingImpersonated() ? "Stop Impersonating" : "Sign Out" }}</a>
                        </div>
                    </li>
                </ul>
            </div>
            <span class="site-nav__end">
                <a href="https://wdfw.wa.gov/" class="wdfw-logo" target="_blank">
                    <img src="../assets/main/logos/WDFW-Logo.png" alt="" />
                </a>
                <button
                    class="hamburger-btn"
                    [dropdownToggle]="mobileMenuToggle"
                    #mobileMenuToggleButton="dropdownToggleName"
                    type="button"
                    aria-controls="hamburger-btn"
                    aria-expanded="false">
                    <span class="sr-only">Open main menu</span>
                    <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                            clip-rule="evenodd"></path>
                    </svg>
                </button>
            </span>
        </nav>
    </div>
</header>
