import { Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { AboutComponent } from "./pages/about/about.component";
import { CreateUserCallbackComponent } from "./pages/create-user-callback/create-user-callback.component";
import { DashboardAdminComponent } from "./pages/dashboard-admin/dashboard-admin.component";
import { FieldDefinitionEditComponent } from "./pages/field-definition-edit/field-definition-edit.component";
import { FieldDefinitionListComponent } from "./pages/field-definition-list/field-definition-list.component";
import { HomeIndexComponent } from "./pages/home/home-index/home-index.component";
import { LoginCallbackComponent } from "./pages/login-callback/login-callback.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { StyleGuideComponent } from "./pages/style-guide/style-guide.component";
import { UserDetailComponent } from "./pages/user-detail/user-detail.component";
import { UserEditComponent } from "./pages/user-edit/user-edit.component";
import { UserListComponent } from "./pages/user-list/user-list.component";
import { WaterResourceInventoryAreaDetailComponent } from "./pages/water-resource-inventory-area/water-resource-inventory-area-detail/water-resource-inventory-area-detail.component";
import { WaterResourceInventoryAreaIndexComponent } from "./pages/water-resource-inventory-area/water-resource-inventory-area-index/water-resource-inventory-area-index.component";
import { FlagEnum } from "./shared/generated/enum/flag-enum";
import { PermissionEnum } from "./shared/generated/enum/permission-enum";
import { withFlagGuard } from "./shared/guards/authorization/with-flag.guard";
import { withRolePermissionGuard } from "./shared/guards/authorization/with-role-permission.guard";
import { UnsavedChangesGuard } from "./shared/guards/unsaved-changes-guard";
import { RightsEnum } from "./shared/models/enums/rights.enum";
import { SubscriptionInsufficientComponent, UnauthenticatedComponent } from "./shared/pages";
import { ReachDetailComponent } from "./pages/reach/reach-detail/reach-detail.component";
import { CountyIndexComponent } from "./pages/county/county-index/county-index.component";
import { CountyDetailComponent } from "./pages/county/county-detail/county-detail.component";
import { DataSourcesComponent } from "./pages/data-sources/data-sources.component";

export const routeParams = {
    userID: "userID",
    fieldDefinitionID: "fieldDefinitionID",
    waterResourceInventoryAreaID: "waterResourceInventoryAreaID",
    countyID: "countyID",
    reachExplorerEntityID: "reachExplorerEntityID",
    permanentIdentifier: "permanentIdentifier",
};

export const routes: Routes = [
    {
        path: "admin",
        component: DashboardAdminComponent,
        title: "Admin Dashboard",
        canActivate: [MsalGuard, withFlagGuard(FlagEnum.HasAdminDashboard)],
        children: [
            { path: "", redirectTo: "terms-and-definitions", pathMatch: "full" },
            {
                path: `terms-and-definitions`,
                component: FieldDefinitionListComponent,
                canDeactivate: [UnsavedChangesGuard],
                title: "Terms & Definitions",
                data: { isReadonly: false },
                canActivate: [withRolePermissionGuard(PermissionEnum.CustomRichTextRights, RightsEnum.Update)],
            },
            {
                path: `terms-and-definitions/:${routeParams.fieldDefinitionID}`,
                component: FieldDefinitionEditComponent,
                canDeactivate: [UnsavedChangesGuard],
                title: "Edit Term Definition",
            },
            { path: "users", component: UserListComponent, title: "Users" },
            { path: `users/:${routeParams.userID}`, component: UserDetailComponent, title: "User Detail" },
            { path: `users/:${routeParams.userID}/edit`, component: UserEditComponent, title: "Edit User" },
        ],
    },
    { path: "wrias", component: WaterResourceInventoryAreaIndexComponent, canActivate: [MsalGuard, withRolePermissionGuard(PermissionEnum.WRIARights, RightsEnum.Read)] },
    {
        path: `wrias/:${routeParams.reachExplorerEntityID}`,
        component: WaterResourceInventoryAreaDetailComponent,
        canActivate: [MsalGuard, withRolePermissionGuard(PermissionEnum.WRIARights, RightsEnum.Read)],
    },
    { path: "counties", component: CountyIndexComponent, canActivate: [MsalGuard, withRolePermissionGuard(PermissionEnum.WRIARights, RightsEnum.Read)] },
    {
        path: `counties/:${routeParams.reachExplorerEntityID}`,
        component: CountyDetailComponent,
        canActivate: [MsalGuard, withRolePermissionGuard(PermissionEnum.WRIARights, RightsEnum.Read)],
    },
    {
        path: `reaches/:${routeParams.permanentIdentifier}`,
        component: ReachDetailComponent,
        canActivate: [MsalGuard, withRolePermissionGuard(PermissionEnum.WRIARights, RightsEnum.Read)],
    },
    { path: "style-guide", title: "Style Guide", component: StyleGuideComponent },
    { path: "about", title: "About", component: AboutComponent },
    { path: "data-sources", title: "Data Sources", component: DataSourcesComponent },
    { path: "terms-and-definitions", title: "Terms & Definitions", component: FieldDefinitionListComponent, data: { isReadonly: true } },
    { path: "create-user-callback", component: CreateUserCallbackComponent },
    { path: "not-found", title: "Page Not Found", component: NotFoundComponent },
    { path: "subscription-insufficient", title: "Insufficient Priveleges", component: SubscriptionInsufficientComponent },
    { path: "unauthenticated", title: "Unauthenticated", component: UnauthenticatedComponent },
    { path: "signin-oidc", component: LoginCallbackComponent },
    { path: "", component: HomeIndexComponent },
    { path: "**", title: "Page Not Found", component: NotFoundComponent },
];
