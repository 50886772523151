import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { CommonModule, DecimalPipe, NgIf, PercentPipe } from "@angular/common";
import { ColDef, ColGroupDef, GridApi, GridReadyEvent } from "ag-grid-community";
import { IconComponent } from "../icon/icon.component";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Options, NgxSliderModule } from "@angular-slider/ngx-slider";
import { SelectDropDownModule } from "ngx-select-dropdown";
import { Subscription } from "rxjs";
import { routeParams } from "src/app/app.routes";
import { environment } from "src/environments/environment";
import { LoadingDirective } from "../../directives/loading.directive";
import { ReachSearchRequestDtoFormControls } from "../../generated/model/reach-search-request-dto";
import { ReachSearchResultDto } from "../../generated/model/reach-search-result-dto";
import { AlertService } from "../../services/alert.service";
import { UtilityFunctionsService } from "../../services/utility-functions.service";
import { WfsService } from "../../services/wfs.service";
import { FieldDefinitionComponent } from "../field-definition/field-definition.component";
import { BtnGroupRadioInputComponent } from "../inputs/btn-group-radio-input/btn-group-radio-input.component";
import { RiparisMapComponent, RiparisMapInitEvent } from "../leaflet/riparis-map/riparis-map.component";
import { RiparisGridComponent } from "../riparis-grid/riparis-grid.component";
import * as L from "leaflet";
import "src/scripts/leaflet.groupedlayercontrol.js";
import { UserFilterObjectTypeEnum } from "../../generated/enum/user-filter-object-type-enum";
import { LandCoverBarChartComponent } from "src/app/shared/components/charts/land-cover-bar-chart/land-cover-bar-chart.component";
import { ChartData } from "../charts/ChartData";
import { NetworkStatisticsDto } from "../../models/network-statistics-dto";
import { SumPipe } from "../../pipes/sum.pipe";
import { ZoneAreaRadialChartComponent } from "../charts/zone-area-radial-chart/zone-area-radial-chart.component";
import { Title } from "@angular/platform-browser";
import { SalmonDistributionBarChartComponent } from "../charts/salmon-distribution-bar-chart/salmon-distribution-bar-chart.component";
import { LandUseWaffleChartComponent } from "../charts/land-use-waffle-chart/land-use-waffle-chart.component";
import { TreeDecreaseAcresStackedAreaChartComponent } from "../charts/tree-decrease-acres-stacked-area-chart/tree-decrease-acres-stacked-area-chart.component";
import { UserFilterObjectService } from "../../generated/api/user-filter-object.service";
import { FilterTag, PopupFilterComponent, PopupFilterModel } from "../popup-filter/popup-filter.component";
import { UserFilterObjectSimpleDto } from "../../generated/model/user-filter-object-simple-dto";
import { FilterTagComponent } from "../filter-tag/filter-tag.component";

@Component({
    selector: "reach-explorer",
    templateUrl: "./reach-explorer.component.html",
    styleUrls: ["./reach-explorer.component.scss"],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        IconComponent,
        NgIf,
        SelectDropDownModule,
        FieldDefinitionComponent,
        NgxSliderModule,
        LoadingDirective,
        RiparisMapComponent,
        RiparisGridComponent,
        DecimalPipe,
        PercentPipe,
        BtnGroupRadioInputComponent,
        LandCoverBarChartComponent,
        SalmonDistributionBarChartComponent,
        ZoneAreaRadialChartComponent,
        CommonModule,
        LandUseWaffleChartComponent,
        TreeDecreaseAcresStackedAreaChartComponent,
        PopupFilterComponent,
        FilterTagComponent,
    ],
})
export class ReachExplorerComponent implements OnInit, OnDestroy, OnChanges {
    @Input() userFilterObjectType: UserFilterObjectTypeEnum;
    @Input() reachExplorerEntities: ReachExplorerEntityDto[];
    @Input() currentReachExplorerEntity: ReachExplorerEntityDto;
    @Input() userID: number;

    public UserFilterObjectTypeEnum = UserFilterObjectTypeEnum;
    public userFilterObject: UserFilterObjectSimpleDto;
    public reachSearchResult: ReachSearchResultDto;

    private allReachExplorerEntitiesFeatureGroup = L.featureGroup();
    public isLoadingReachExplorerEntities: boolean = false;
    public isLoadingLandCoverChartData: boolean = false;
    public isLoadingLandUseChartData: boolean = false;
    public isLoadingTreeDecreaseAcresChartData: boolean = false;
    public isLoadingSalmonDistributionChartData: boolean = false;
    public isLoadingReachTable: boolean = false;

    public wmsOptionsFilteredReaches: L.WMSOptions;
    public wmsOptionsAllReaches: L.WMSOptions;
    public wmsOptionsFilteredReachZones: L.WMSOptions;
    public wmsOptionsAllReachZones: L.WMSOptions;
    public wmsOptionsAllLandCovers: L.WMSOptions;
    public allReachesLayer;
    public filteredReachesLayer;
    public allReachZonesLayer;
    public filteredReachZonesLayer;
    public allLandCoversLayer;
    public allNetworkStatistics: NetworkStatisticsDto;
    public landCoverChartData: ChartData[];
    public treeDecreaseAcresChartData: ChartData[];
    public salmonDistributionChartData: ChartData[];
    public zoneAreaChartData: ChartData[];
    public landUseChartData: ChartData[];

    public activeTab: string = "Map";
    public tabs = [
        { label: "Map", value: "Map" },
        { label: "Land Cover", value: "LandCover" },
        { label: "Water Quality", value: "WaterQuality", disabled: true },
        { label: "Hydrology", value: "Hydrology" },
        { label: "Salmon", value: "Salmon" },
        { label: "Administrative", value: "Administrative" },
        { label: "Reach Table", value: "Reach" },
    ];

    public gridApi: GridApi;

    public columnDefs: (ColDef | ColGroupDef)[];

    public reachExplorerEntityDropdownConfig = {
        search: true,
        height: "320px",
        placeholder: "",
        searchOnKey: "EntityIDAndName",
    };

    public formGroup: FormGroup<any> = new FormGroup<any>({
        EntityID: ReachSearchRequestDtoFormControls.EntityID(),
        FilterToEOW: ReachSearchRequestDtoFormControls.FilterToEOW(),
        FilterToRMZ: ReachSearchRequestDtoFormControls.FilterToRMZ(),
        FilterToFlood: ReachSearchRequestDtoFormControls.FilterToFlood(),
        PercentageTreeCover: ReachSearchRequestDtoFormControls.PercentageTreeCover([0, 100]),
        PercentageVegetated: ReachSearchRequestDtoFormControls.PercentageVegetated([0, 100]),
        FilterToEcology305BList: ReachSearchRequestDtoFormControls.FilterToEcology305BList(),
        FilterToEcology303DList: ReachSearchRequestDtoFormControls.FilterToEcology303DList(),
        FilterToNotTemperatureImpaired: ReachSearchRequestDtoFormControls.FilterToNotTemperatureImpaired(),
        FilterToFishBarriersUnknownPassability: ReachSearchRequestDtoFormControls.FilterToFishBarriersUnknownPassability(),
        FilterToFishBarriersNonPassable: ReachSearchRequestDtoFormControls.FilterToFishBarriersNonPassable(),
        FilterToFishBarriersPassable: ReachSearchRequestDtoFormControls.FilterToFishBarriersPassable(),
        FilterToFishBarriersNoKnownBarrier: ReachSearchRequestDtoFormControls.FilterToFishBarriersNoKnownBarrier(),
        FilterToSalmonBearing: ReachSearchRequestDtoFormControls.FilterToSalmonBearing(),
        FilterToNonSalmonBearing: ReachSearchRequestDtoFormControls.FilterToNonSalmonBearing(),
    });

    public formGroupDefaultValues = {
        FilterToEOW: true,
        FilterToRMZ: true,
        FilterToFlood: true,
        PercentageTreeCover: [0, 100],
        PercentageVegetated: [0, 100],
        FilterToEcology305BList: true,
        FilterToEcology303DList: true,
        FilterToNotTemperatureImpaired: true,
        FilterToFishBarriersUnknownPassability: true,
        FilterToFishBarriersNonPassable: true,
        FilterToFishBarriersPassable: true,
        FilterToFishBarriersNoKnownBarrier: true,
        FilterToSalmonBearing: true,
        FilterToNonSalmonBearing: true,
    };

    public showPopupFilter: boolean = false;
    public currentFilterModel: PopupFilterModel;

    public speciesAndEcologicalDataFilterModel: PopupFilterModel = {
        title: "Species & Ecological Data",
        titleIcon: "Salmon",
        fields: [
            {
                type: "Checklist",
                header: "303(d) Temp Impairment",
                headerFieldDefinitionType: "TemperatureImpairedWaterbodies",
                options: [
                    { name: "Ecology 305(b) List", formControlName: "FilterToEcology305BList" },
                    { name: "Ecology 303(d) List", formControlName: "FilterToEcology303DList" },
                    { name: "Not Impaired", formControlName: "FilterToNotTemperatureImpaired" },
                ],
            },
            {
                type: "Checklist",
                header: "SWIFD Salmon Species",
                headerFieldDefinitionType: "SWIFDSalmonDistribution",
                options: [
                    { name: "Salmon Bearing", formControlName: "FilterToSalmonBearing" },
                    { name: "Non-Salmon Bearing", formControlName: "FilterToNonSalmonBearing" },
                ],
            },
        ],
    };

    public rangeSliderOptions: Options = {
        floor: 0,
        ceil: 100,
        step: 5,
    };

    public environmentalAndRiparianCharacteristicsFilterModel: PopupFilterModel = {
        title: "Environmental & Riparian Characteristics",
        titleIcon: "LandCover",
        fields: [
            {
                type: "Range",
                header: "Reach Percent Vegetated",
                headerFieldDefinitionType: "PercentVegetated",
                labelOverride: "Reach Percent Vegetated",
                formControlName: "PercentageVegetated",
                rangeSliderOptions: this.rangeSliderOptions,
            },
            {
                type: "Range",
                header: "Reach Percent Tree Cover",
                headerFieldDefinitionType: "PercentTreeCover",
                labelOverride: "Reach Percent Tree Cover",
                formControlName: "PercentageTreeCover",
                rangeSliderOptions: this.rangeSliderOptions,
            },
        ],
    };

    public fishPassageBarriersAndAccessFilterModel: PopupFilterModel = {
        title: "Fish Passage Barriers & Access",
        titleIcon: "Barrier",
        fields: [
            {
                type: "Checklist",
                header: "Local Fish Passage Barriers",
                headerFieldDefinitionType: "FishPassageBarriers",
                options: [
                    { name: "Unknown Passability", formControlName: "FilterToFishBarriersUnknownPassability" },
                    { name: "Not Passable", formControlName: "FilterToFishBarriersNonPassable" },
                    { name: "Passable", formControlName: "FilterToFishBarriersPassable" },
                    { name: "No Known Barrier", formControlName: "FilterToFishBarriersNoKnownBarrier" },
                ],
            },
        ],
    };

    private defaultStyle = {
        color: "#004c73",
        weight: 2.5,
        fillOpacity: 0,
    };

    private geoserverWFSSubscription: Subscription = Subscription.EMPTY;
    private reachExplorerEntityIDRouteParam: any;

    constructor(
        private userFilterObjectService: UserFilterObjectService,
        private alertService: AlertService,
        private utilityFunctionsService: UtilityFunctionsService,
        private router: Router,
        private route: ActivatedRoute,
        private wfsService: WfsService,
        private sumPipe: SumPipe,
        private title: Title
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.mapIsReady && Object.keys(changes).includes("currentReachExplorerEntity")) {
            this.updateReachExplorerEntity();
            this.displayReachExplorerEntity();
            this.updateReachZones();
        }
    }

    ngOnDestroy(): void {
        this.geoserverWFSSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.reachExplorerEntityIDRouteParam = params[routeParams.reachExplorerEntityID];
        });
    }

    public launchPopupFilter(filterModel: PopupFilterModel) {
        this.currentFilterModel = filterModel;
        this.showPopupFilter = true;
    }

    public applyFilters() {
        this.filter();
        this.closeFilterPopup();
    }

    public closeFilterPopup() {
        this.showPopupFilter = false;
        this.currentFilterModel = null;
    }

    private updateReachExplorerEntity() {
        this.title.setTitle(`${this.currentReachExplorerEntity.EntityName} ${UserFilterObjectTypeEnum[this.userFilterObjectType]} | Map Explorer | Riparian Data Engine`);
        this.formGroup.controls.EntityID.patchValue(this.currentReachExplorerEntity.EntityID);
        this.displayReachExplorerEntity();
        this.displayAllReaches();
        this.resetAndFilter();
    }

    public setActiveTab(event) {
        this.activeTab = event;
        if (this.activeTab === "LandCover") {
            this.createLandCoverChartData();
            this.createTreeDecreaseAcresChartData();
        } else if (this.activeTab === "Hydrology") {
            this.createZoneAreaChartData();
        } else if (this.activeTab === "Salmon") {
            this.createSalmonDistributionChartData();
        } else if (this.activeTab === "Administrative") {
            this.createLandUseChartData();
        } else if (this.activeTab === "Reach") {
            this.createReachTable();
        }
    }

    // the map stuff
    public map: L.Map;
    public layerControl: L.groupedlayercontrol;
    public mapIsReady: boolean = false;

    handleMapReady(event: RiparisMapInitEvent): void {
        this.map = event.map;
        this.layerControl = event.layerControl;
        this.mapIsReady = true;

        this.createColumnDefs();
        this.updateReachExplorerEntity();
        const wfsService = this.wfsService;
        const self = this;

        const zoomToReachExplorerEntityButton = L.control({ position: "topleft" });
        zoomToReachExplorerEntityButton.onAdd = () => {
            const buttonDiv = L.DomUtil.create("a", "leaflet-control-zoom-custom");
            buttonDiv.role = "button";
            buttonDiv.title = "Zoom to " + this.userFilterObjectType + " Extent";

            buttonDiv.innerHTML = `<svg width="18" height="30" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.57143 11.5H1V7.92857M7.42857 1.5H11V5.07143" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`;
            buttonDiv.addEventListener("click", () => self.zoomToReachExplorerEntity());
            return buttonDiv;
        };
        zoomToReachExplorerEntityButton.addTo(this.map);
        this.map.fullscreenControl._container.appendChild(zoomToReachExplorerEntityButton._container);

        this.map.on("click", (event: L.LeafletMouseEvent): void => {
            self.map.fireEvent("dataloading");
            wfsService.getPIDByCoordinate(event.latlng.lng, event.latlng.lat).subscribe((reachPID: string) => {
                const startIndex = reachPID.indexOf("<Riparis:PermanentIdentifier>");
                const endIndex = reachPID.indexOf("</Riparis:PermanentIdentifier>");
                self.map.fireEvent("dataload");
                if (startIndex < 0 && endIndex < 0) {
                    return;
                }

                const permanentIdentifier = reachPID.substring(startIndex + 29, endIndex);

                // IMPORTANT: THIS ONLY WORKS BECAUSE I'VE INSTALLED @angular/elements AND CONFIGURED THIS IN THE app.module.ts bootstrapping
                L.popup({
                    maxWidth: 250,
                    keepInView: true,
                })
                    .setLatLng(event.latlng)
                    .setContent(`<reach-popup-custom-element permanent-identifier="${permanentIdentifier}"></reach-popup-custom-element>`)
                    .openOn(self.map);
            });
        });
    }

    private createColumnDefs() {
        this.columnDefs = [
            this.utilityFunctionsService.createLinkColumnDef("PID", "PermanentIdentifier", "PermanentIdentifier", {
                InRouterLink: "/reaches/",
                Target: "_blank",
                FieldDefinitionType: "PermanentIdentifier",
                Width: 200,
            }),
            this.utilityFunctionsService.createBasicColumnDef("Stream", "StreamName", { Width: 150, FieldDefinitionType: "StreamName" }),
            this.utilityFunctionsService.createDecimalColumnDef("Total Reach Acres", "ReachAcres", {
                Width: 150,
                FieldDefinitionType: "Acres",
                FieldDefinitionLabelOverride: "Total Reach (ac)",
            }),
            this.utilityFunctionsService.createDecimalColumnDef("% Tree Cover", "PercentTreeCover", {
                Width: 150,
                FieldDefinitionType: "PercentTreeCover",
                FieldDefinitionLabelOverride: "% Tree Cover",
            }),
            this.utilityFunctionsService.createDecimalColumnDef("% Vegetated", "PercentVegetated", {
                Width: 135,
                FieldDefinitionType: "PercentVegetated",
                FieldDefinitionLabelOverride: "% Vegetated",
            }),
            this.utilityFunctionsService.createDecimalColumnDef("Natural Change Acres", "NaturalChangeOriginAcres", {
                Width: 175,
                FieldDefinitionType: "NaturalChangeAcres",
                DecimalPlacesToDisplay: 1,
                FieldDefinitionLabelOverride: "Natural Change (ac)",
            }),
            this.utilityFunctionsService.createDecimalColumnDef("Anthropogenic Change Acres", "AnthropogenicChangeOriginAcres", {
                Width: 225,
                FieldDefinitionType: "AnthropogenicChangeAcres",
                DecimalPlacesToDisplay: 1,
                FieldDefinitionLabelOverride: "Anthropogenic Change (ac)",
            }),
            this.utilityFunctionsService.createBasicColumnDef("Temperature Impairments", "TemperatureImpairments", {
                Width: 225,
                FieldDefinitionType: "TemperatureImpairedWaterbodies",
                FieldDefinitionLabelOverride: "Temperature Impairements",
            }),
            this.utilityFunctionsService.createBasicColumnDef("SWIFD Salmon Distribution", "SWIFDSalmonDistribution", { FieldDefinitionType: "SWIFDSalmonDistribution" }),
            {
                headerName: "Ownership (ac)",
                children: [
                    this.utilityFunctionsService.createDecimalColumnDef("Federal", "FederalAcres", { Width: 150, FieldDefinitionType: "Federal" }),
                    this.utilityFunctionsService.createDecimalColumnDef("Municipal", "MunicipalAcres", { Width: 150, FieldDefinitionType: "Municipal" }),
                    this.utilityFunctionsService.createDecimalColumnDef("Private/NGO", "PrivateNGOAcres", { Width: 150, FieldDefinitionType: "PrivateNGO" }),
                    this.utilityFunctionsService.createDecimalColumnDef("State", "StateAcres", { Width: 150, FieldDefinitionType: "State" }),
                    this.utilityFunctionsService.createDecimalColumnDef("Tribal", "TribalAcres", { Width: 150, FieldDefinitionType: "Tribal" }),
                ],
            },
            this.utilityFunctionsService.createDecimalColumnDef("Canopy Pattern Metric", "CanopyPatternMetric", { Width: 200, FieldDefinitionType: "CanopyPatternMetric" }),
            {
                headerName: "Topography",
                children: [
                    this.utilityFunctionsService.createBasicColumnDef("Bank Aspects", "BankAspects", { Width: 150, FieldDefinitionType: "BankAspects" }),
                    this.utilityFunctionsService.createDecimalColumnDef("Average Canopy Height", "AverageCanopyHeight", {
                        Width: 225,
                        FieldDefinitionType: "AverageCanopyHeight",
                        FieldDefinitionLabelOverride: "Average Canopy Height (ft)",
                        DecimalPlacesToDisplay: 0,
                    }),
                    this.utilityFunctionsService.createDecimalColumnDef("Average Elevation", "AverageElevation", {
                        Width: 200,
                        FieldDefinitionType: "AverageElevation",
                        FieldDefinitionLabelOverride: "Average Elevation (ft)",
                        DecimalPlacesToDisplay: 0,
                    }),
                    this.utilityFunctionsService.createDecimalColumnDef("Average Slope", "AverageSlope", {
                        Width: 200,
                        FieldDefinitionType: "AverageSlope",
                        FieldDefinitionLabelOverride: "Average Slope (degrees)",
                        DecimalPlacesToDisplay: 0,
                    }),
                    this.utilityFunctionsService.createDecimalColumnDef("Average Solar", "AverageSolar", {
                        Width: 200,
                        FieldDefinitionType: "AverageSolar",
                        FieldDefinitionLabelOverride: "Average Solar (WH/m^2)",
                        DecimalPlacesToDisplay: 0,
                    }),
                ],
            },
        ];
    }

    private displayReachExplorerEntity(): void {
        this.geoserverWFSSubscription.unsubscribe();
        let cql_filter: string;
        let wfsLayerName: string;
        switch (this.userFilterObjectType) {
            case UserFilterObjectTypeEnum.Watershed:
                cql_filter = `WaterResourceInventoryAreaID in (${this.currentReachExplorerEntity.EntityID})`;
                wfsLayerName = "Riparis:Wrias";
                break;
            case UserFilterObjectTypeEnum.County:
                cql_filter = `CountyID in (${this.currentReachExplorerEntity.EntityID})`;
                wfsLayerName = "Riparis:Counties";
                break;
            case UserFilterObjectTypeEnum.City_UrbanGrowthArea:
                cql_filter = `CityUrbanGrowthAreaID in (${this.currentReachExplorerEntity.EntityID})`;
                wfsLayerName = "Riparis:CityUrbanGrowthAreas";
                break;
        }

        this.geoserverWFSSubscription = this.wfsService.getGeoserverWFSLayerWithCQLFilter(wfsLayerName, cql_filter).subscribe((response) => {
            this.allReachExplorerEntitiesFeatureGroup.clearLayers();
            const geoJson = L.geoJSON(response, {
                style: this.defaultStyle,
            });
            this.allReachExplorerEntitiesFeatureGroup.addLayer(geoJson);
            this.allReachExplorerEntitiesFeatureGroup.addTo(this.map);
            this.zoomToReachExplorerEntity();
        });
    }

    private zoomToReachExplorerEntity() {
        this.map.fitBounds(this.allReachExplorerEntitiesFeatureGroup.getBounds());
    }

    public filter() {
        this.isLoadingReachExplorerEntities = true;
        this.userFilterObjectService.userFilterObjectsFilterObjectTypeUserFilterObjectTypeIDSearchPost(this.userFilterObjectType, this.formGroup.value).subscribe((response) => {
            this.isLoadingReachExplorerEntities = false;
            this.userFilterObject = response;
            this.alertService.clearAlerts();
            this.createReachTable();
            this.createLandCoverChartData();
            this.createTreeDecreaseAcresChartData();
            this.createZoneAreaChartData();
            this.createSalmonDistributionChartData();
            this.createLandUseChartData();
            this.updateReachZones();
        });
    }

    private displayAllReaches(): void {
        if (this.allLandCoversLayer && this.layerControl) {
            this.map.removeLayer(this.allLandCoversLayer);
            this.layerControl.removeLayer(this.allLandCoversLayer);
        }
        if (this.allReachesLayer && this.layerControl) {
            this.map.removeLayer(this.allReachesLayer);
            this.layerControl.removeLayer(this.allReachesLayer);
        }
        if (this.allReachZonesLayer && this.layerControl) {
            this.map.removeLayer(this.allReachZonesLayer);
            this.layerControl.removeLayer(this.allReachZonesLayer);
        }

        let cql_filter: string;
        switch (this.userFilterObjectType) {
            case UserFilterObjectTypeEnum.Watershed:
                cql_filter = `WaterResourceInventoryAreaID in (${this.currentReachExplorerEntity.EntityID})`;
                break;
            case UserFilterObjectTypeEnum.County:
                cql_filter = `CountyID in (${this.currentReachExplorerEntity.EntityID})`;
                break;
            case UserFilterObjectTypeEnum.City_UrbanGrowthArea:
                cql_filter = `CityUrbanGrowthAreaID in (${this.currentReachExplorerEntity.EntityID})`;
                break;
        }

        this.wmsOptionsAllReaches = {
            layers: "Riparis:AllStreams",
            transparent: true,
            format: "image/png",
            tiled: true,
            styles: "stream",
        };
        this.wmsOptionsAllReachZones = {
            layers: "Riparis:AllReachZones",
            transparent: true,
            format: "image/png",
            tiled: true,
            styles: "reachzone",
        };
        this.wmsOptionsAllLandCovers = {
            layers: "Riparis:LandCovers",
            transparent: true,
            format: "image/png",
            tiled: true,
            styles: "landcover",
        };

        this.wmsOptionsAllReaches.cql_filter = cql_filter;
        this.wmsOptionsAllReachZones.cql_filter = cql_filter;
        this.wmsOptionsAllLandCovers.cql_filter = cql_filter;
        this.allReachesLayer = L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", this.wmsOptionsAllReaches);
        this.allReachZonesLayer = L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", this.wmsOptionsAllReachZones);
        this.allLandCoversLayer = L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", this.wmsOptionsAllLandCovers);
        this.layerControl.addOverlay(this.allLandCoversLayer, "Land Cover", "Land Cover");
    }

    private updateReachZones(): void {
        if (this.allReachesLayer && this.layerControl) {
            this.map.removeLayer(this.allReachesLayer);
            this.layerControl.removeLayer(this.allReachesLayer);
        }
        if (this.allReachZonesLayer && this.layerControl) {
            this.map.removeLayer(this.allReachZonesLayer);
            this.layerControl.removeLayer(this.allReachZonesLayer);
        }
        if (this.filteredReachesLayer && this.layerControl) {
            this.map.removeLayer(this.filteredReachesLayer);
            this.layerControl.removeLayer(this.filteredReachesLayer);
        }

        if (this.filteredReachZonesLayer && this.layerControl) {
            this.map.removeLayer(this.filteredReachZonesLayer);
            this.layerControl.removeLayer(this.filteredReachZonesLayer);
        }

        const cql_filter = `UserID = ${this.userID} and UserFilterObjectTypeID = ${this.userFilterObjectType}`;
        this.wmsOptionsFilteredReaches = {
            layers: "Riparis:Streams",
            transparent: true,
            format: "image/png",
            tiled: true,
            styles: "stream_selected",
            fake: Date.now(),
        };

        this.wmsOptionsFilteredReachZones = {
            layers: "Riparis:ReachZones",
            transparent: true,
            format: "image/png",
            tiled: true,
            styles: "reachzone_selected",
            fake: Date.now(),
        };

        this.wmsOptionsFilteredReaches.cql_filter = cql_filter;
        this.wmsOptionsFilteredReachZones.cql_filter = cql_filter;
        this.filteredReachesLayer = L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", this.wmsOptionsFilteredReaches);
        this.filteredReachZonesLayer = L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", this.wmsOptionsFilteredReachZones);
        this.layerControl.addOverlay(this.allReachZonesLayer, "All", "Riparian Areas");
        this.map.addLayer(this.allReachZonesLayer);
        this.layerControl.addOverlay(this.filteredReachZonesLayer, "Filtered", "Riparian Areas");
        this.map.addLayer(this.filteredReachZonesLayer);
        this.layerControl.addOverlay(this.allReachesLayer, "All", "Streams");
        this.map.addLayer(this.allReachesLayer);
        this.layerControl.addOverlay(this.filteredReachesLayer, "Filtered", "Streams");
        this.map.addLayer(this.filteredReachesLayer);
    }

    public reset() {
        this.formGroup.controls.EntityID.patchValue(this.currentReachExplorerEntity.EntityID);

        Object.keys(this.formGroupDefaultValues).forEach((formControlName) => {
            this.formGroup.controls[formControlName].patchValue(this.formGroupDefaultValues[formControlName]);
        });

        this.resetAllFilterTags();
    }

    public resetAllFilterTags() {
        this.fishPassageBarriersAndAccessFilterModel.tags = [];
        this.speciesAndEcologicalDataFilterModel.tags = [];
        this.environmentalAndRiparianCharacteristicsFilterModel.tags = [];
    }

    public resetAndFilter() {
        this.reset();
        this.filter();
    }

    public onFilterTagRemoved(tag: FilterTag, filterModel: PopupFilterModel, tagIndex: number) {
        // remove tag from filter model
        filterModel.tags.splice(tagIndex, 1);

        const field = filterModel.fields.find((x) => x.header == tag.fieldName);
        if (!field) {
            console.error(`Filter field "${tag.fieldName}" not found for "${filterModel.title}" filter`);
            return;
        }

        // reset formGroup values to default
        if (field.formControlName) {
            this.formGroup.controls[field.formControlName].patchValue(this.formGroupDefaultValues[field.formControlName]);
        } else {
            field.options.forEach((option) => {
                this.formGroup.controls[option.formControlName].patchValue(this.formGroupDefaultValues[option.formControlName]);
            });
        }
        this.filter();
    }

    public changedReachExplorerEntity() {
        this.router.navigateByUrl(this.router.url.replace(this.reachExplorerEntityIDRouteParam, this.currentReachExplorerEntity.EntityID.toString()));
    }

    public onGridReady(event: GridReadyEvent) {
        this.gridApi = event.api;
    }

    public isWatershed() {
        return this.userFilterObjectType === UserFilterObjectTypeEnum.Watershed;
    }

    public createReachTable() {
        this.isLoadingReachTable = true;
        if (this.userFilterObject) {
            this.userFilterObjectService.userFilterObjectsUserFilterObjectIDReachTableGet(this.userFilterObject.UserFilterObjectID).subscribe((result) => {
                this.reachSearchResult = result;
                this.isLoadingReachTable = false;
            });
        }
    }

    public createLandCoverChartData() {
        this.isLoadingLandCoverChartData = true;
        if (this.userFilterObject) {
            this.userFilterObjectService.userFilterObjectsUserFilterObjectIDLandCoverAcresGet(this.userFilterObject.UserFilterObjectID).subscribe((result) => {
                this.landCoverChartData = [];
                const AllLandCoverAcres = this.sumPipe.transform(result, "AllLandCoverAcres");
                const EOWTreeCoverAcres = this.sumPipe.transform(result, "EOWTreeCoverAcres");
                const RMZTreeCoverAcres = this.sumPipe.transform(result, "RMZTreeCoverAcres");
                const FloodTreeCoverAcres = this.sumPipe.transform(result, "FloodTreeCoverAcres");
                const EOWHerbaceousAcres = this.sumPipe.transform(result, "EOWHerbaceousAcres");
                const RMZHerbaceousAcres = this.sumPipe.transform(result, "RMZHerbaceousAcres");
                const FloodHerbaceousAcres = this.sumPipe.transform(result, "FloodHerbaceousAcres");
                const EOWShrubAcres = this.sumPipe.transform(result, "EOWShrubAcres");
                const RMZShrubAcres = this.sumPipe.transform(result, "RMZShrubAcres");
                const FloodShrubAcres = this.sumPipe.transform(result, "FloodShrubAcres");
                const EOWWaterAcres = this.sumPipe.transform(result, "EOWWaterAcres");
                const RMZWaterAcres = this.sumPipe.transform(result, "RMZWaterAcres");
                const FloodWaterAcres = this.sumPipe.transform(result, "FloodWaterAcres");
                const EOWNoVegetationDataAcres = this.sumPipe.transform(result, "EOWNoVegetationDataAcres");
                const RMZNoVegetationDataAcres = this.sumPipe.transform(result, "RMZNoVegetationDataAcres");
                const FloodNoVegetationDataAcres = this.sumPipe.transform(result, "FloodNoVegetationDataAcres");
                const EOWNonVegetatedAcres = this.sumPipe.transform(result, "EOWNonVegetatedAcres");
                const RMZNonVegetatedAcres = this.sumPipe.transform(result, "RMZNonVegetatedAcres");
                const FloodNonVegetatedAcres = this.sumPipe.transform(result, "FloodNonVegetatedAcres");

                this.landCoverChartData.push({
                    XValue: "Herbaceous",
                    Group: "Extent of Observed Water",
                    YValue: EOWHerbaceousAcres,
                    SortOrder: 11,
                    HoverValue: EOWHerbaceousAcres / AllLandCoverAcres,
                });
                this.landCoverChartData.push({
                    XValue: "Herbaceous",
                    Group: "Riparian Management Zone",
                    YValue: RMZHerbaceousAcres,
                    SortOrder: 12,
                    HoverValue: RMZHerbaceousAcres / AllLandCoverAcres,
                });
                this.landCoverChartData.push({
                    XValue: "Herbaceous",
                    Group: "Floodplain",
                    YValue: FloodHerbaceousAcres,
                    SortOrder: 13,
                    HoverValue: FloodHerbaceousAcres / AllLandCoverAcres,
                });
                this.landCoverChartData.push({
                    XValue: "Tree",
                    Group: "Extent of Observed Water",
                    YValue: EOWTreeCoverAcres,
                    SortOrder: 17,
                    HoverValue: EOWTreeCoverAcres / AllLandCoverAcres,
                });
                this.landCoverChartData.push({
                    XValue: "Tree",
                    Group: "Riparian Management Zone",
                    YValue: RMZTreeCoverAcres,
                    SortOrder: 18,
                    HoverValue: RMZTreeCoverAcres / AllLandCoverAcres,
                });
                this.landCoverChartData.push({
                    XValue: "Tree",
                    Group: "Floodplain",
                    YValue: FloodTreeCoverAcres,
                    SortOrder: 19,
                    HoverValue: FloodTreeCoverAcres / AllLandCoverAcres,
                });
                this.landCoverChartData.push({
                    XValue: "Shrub",
                    Group: "Extent of Observed Water",
                    YValue: EOWShrubAcres,
                    SortOrder: 14,
                    HoverValue: EOWShrubAcres / AllLandCoverAcres,
                });
                this.landCoverChartData.push({
                    XValue: "Shrub",
                    Group: "Riparian Management Zone",
                    YValue: RMZShrubAcres,
                    SortOrder: 15,
                    HoverValue: RMZShrubAcres / AllLandCoverAcres,
                });
                this.landCoverChartData.push({
                    XValue: "Shrub",
                    Group: "Floodplain",
                    YValue: FloodShrubAcres,
                    SortOrder: 16,
                    HoverValue: FloodShrubAcres / AllLandCoverAcres,
                });
                this.landCoverChartData.push({
                    XValue: "Water",
                    Group: "Extent of Observed Water",
                    YValue: EOWWaterAcres,
                    SortOrder: 23,
                    HoverValue: EOWWaterAcres / AllLandCoverAcres,
                });
                this.landCoverChartData.push({
                    XValue: "Water",
                    Group: "Riparian Management Zone",
                    YValue: RMZWaterAcres,
                    SortOrder: 24,
                    HoverValue: RMZWaterAcres / AllLandCoverAcres,
                });
                this.landCoverChartData.push({
                    XValue: "Water",
                    Group: "Floodplain",
                    YValue: FloodWaterAcres,
                    SortOrder: 25,
                    HoverValue: FloodWaterAcres / AllLandCoverAcres,
                });
                this.landCoverChartData.push({
                    XValue: "No Vegetation Data",
                    Group: "Extent of Observed Water",
                    YValue: EOWNoVegetationDataAcres,
                    SortOrder: 26,
                    HoverValue: EOWNoVegetationDataAcres / AllLandCoverAcres,
                });
                this.landCoverChartData.push({
                    XValue: "No Vegetation Data",
                    Group: "Riparian Management Zone",
                    YValue: RMZNoVegetationDataAcres,
                    SortOrder: 27,
                    HoverValue: RMZNoVegetationDataAcres / AllLandCoverAcres,
                });
                this.landCoverChartData.push({
                    XValue: "No Vegetation Data",
                    Group: "Floodplain",
                    YValue: FloodNoVegetationDataAcres,
                    SortOrder: 28,
                    HoverValue: FloodNoVegetationDataAcres / AllLandCoverAcres,
                });
                this.landCoverChartData.push({
                    XValue: "Non-Vegetated",
                    Group: "Extent of Observed Water",
                    YValue: EOWNonVegetatedAcres,
                    SortOrder: 20,
                    HoverValue: EOWNonVegetatedAcres / AllLandCoverAcres,
                });
                this.landCoverChartData.push({
                    XValue: "Non-Vegetated",
                    Group: "Riparian Management Zone",
                    YValue: RMZNonVegetatedAcres,
                    SortOrder: 21,
                    HoverValue: RMZNonVegetatedAcres / AllLandCoverAcres,
                });
                this.landCoverChartData.push({
                    XValue: "Non-Vegetated",
                    Group: "Floodplain",
                    YValue: FloodNonVegetatedAcres,
                    SortOrder: 22,
                    HoverValue: FloodNonVegetatedAcres / AllLandCoverAcres,
                });
                this.isLoadingLandCoverChartData = false;
            });
        }
    }

    public createTreeDecreaseAcresChartData() {
        this.isLoadingTreeDecreaseAcresChartData = true;
        if (this.userFilterObject) {
            this.userFilterObjectService
                .userFilterObjectsUserFilterObjectIDAnnualChangeDetectionGet(this.userFilterObject.UserFilterObjectID)
                .subscribe((reachAnnualChangeDetections) => {
                    this.treeDecreaseAcresChartData = [];
                    const years = [...new Set(reachAnnualChangeDetections.map((item) => item.Year))];
                    years.forEach((year) => {
                        this.treeDecreaseAcresChartData.push({
                            XValue: year,
                            Group: "Forestry",
                            YValue: this.sumPipe.transform(
                                reachAnnualChangeDetections.filter((x) => x.Year === year),
                                "ForestryTreeDecreaseAcres"
                            ),
                            SortOrder: 4,
                        });
                        this.treeDecreaseAcresChartData.push({
                            XValue: year,
                            Group: "General Tree Removal",
                            YValue: this.sumPipe.transform(
                                reachAnnualChangeDetections.filter((x) => x.Year === year),
                                "GeneralTreeRemovalTreeDecreaseAcres"
                            ),
                            SortOrder: 3,
                        });
                        this.treeDecreaseAcresChartData.push({
                            XValue: year,
                            Group: "Other Human Causes",
                            YValue: this.sumPipe.transform(
                                reachAnnualChangeDetections.filter((x) => x.Year === year),
                                "OtherHumanCausesTreeDecreaseAcres"
                            ),
                            SortOrder: 2,
                        });

                        this.treeDecreaseAcresChartData.push({
                            XValue: year,
                            Group: "Natural Causes",
                            YValue: this.sumPipe.transform(
                                reachAnnualChangeDetections.filter((x) => x.Year === year),
                                "NaturalCausesTreeDecreaseAcres"
                            ),
                            SortOrder: 1,
                        });
                    });
                    this.isLoadingTreeDecreaseAcresChartData = false;
                });
        }
    }

    public createLandUseChartData() {
        this.isLoadingLandUseChartData = true;
        if (this.userFilterObject) {
            this.userFilterObjectService.userFilterObjectsUserFilterObjectIDLandUseAcresGet(this.userFilterObject.UserFilterObjectID).subscribe((result) => {
                this.landUseChartData = [];
                const AgricultureAcres = this.sumPipe.transform(result, "AgricultureAcres");
                const AgricultureChapter83Acres = this.sumPipe.transform(result, "AgricultureChapter83Acres");
                const AgricultureNotLaw81Acres = this.sumPipe.transform(result, "AgricultureNotLaw81Acres");
                const CommercialAcres = this.sumPipe.transform(result, "CommercialAcres");
                const ForestryForestLandAcres = this.sumPipe.transform(result, "ForestryForestLandAcres");
                const ForestTimberlandDataAcres = this.sumPipe.transform(result, "ForestTimberlandDataAcres");
                const OpenSpaceAcres = this.sumPipe.transform(result, "OpenSpaceAcres");
                const ResidentialAcres = this.sumPipe.transform(result, "ResidentialAcres");
                const ResourceAcres = this.sumPipe.transform(result, "ResourceAcres");
                const TransportationAcres = this.sumPipe.transform(result, "TransportationAcres");
                const UndevelopedLandAcres = this.sumPipe.transform(result, "UndevelopedLandAcres");
                const UndevelopedLands91Acres = this.sumPipe.transform(result, "UndevelopedLands91Acres");
                const AllLandUseAcres = this.sumPipe.transform(result, "AllLandUseAcres");

                this.landUseChartData.push({
                    XValue: "Agriculture",
                    YValue: AgricultureAcres,
                    HoverValue: AgricultureAcres / AllLandUseAcres,
                });
                this.landUseChartData.push({
                    XValue: "Agriculture (ch 83.34 RCW)",
                    YValue: AgricultureChapter83Acres,
                    HoverValue: AgricultureChapter83Acres / AllLandUseAcres,
                });
                this.landUseChartData.push({
                    XValue: "Agriculture (not classified)",
                    YValue: AgricultureNotLaw81Acres,
                    HoverValue: AgricultureNotLaw81Acres / AllLandUseAcres,
                });
                this.landUseChartData.push({
                    XValue: "Commercial/Industrial",
                    YValue: CommercialAcres,
                    HoverValue: CommercialAcres / AllLandUseAcres,
                });
                this.landUseChartData.push({
                    XValue: "Forestry (ch 84.33 RCW)",
                    YValue: ForestryForestLandAcres,
                    HoverValue: ForestryForestLandAcres / AllLandUseAcres,
                });
                this.landUseChartData.push({
                    XValue: "Forestry (ch 84.34 RCW)",
                    YValue: ForestTimberlandDataAcres,
                    HoverValue: ForestTimberlandDataAcres / AllLandUseAcres,
                });
                this.landUseChartData.push({
                    XValue: "Open Space (ch 84.34 RCW)",
                    YValue: OpenSpaceAcres,
                    HoverValue: OpenSpaceAcres / AllLandUseAcres,
                });
                this.landUseChartData.push({
                    XValue: "Residential",
                    YValue: ResidentialAcres,
                    HoverValue: ResidentialAcres / AllLandUseAcres,
                });
                this.landUseChartData.push({
                    XValue: "Resource Production & Extraction",
                    YValue: ResourceAcres,
                    HoverValue: ResourceAcres / AllLandUseAcres,
                });
                this.landUseChartData.push({
                    XValue: "Infrastructure",
                    YValue: TransportationAcres,
                    HoverValue: TransportationAcres / AllLandUseAcres,
                });
                this.landUseChartData.push({
                    XValue: "Undeveloped Land & Water Areas",
                    YValue: UndevelopedLandAcres,
                    HoverValue: UndevelopedLandAcres / AllLandUseAcres,
                });
                this.landUseChartData.push({
                    XValue: "Undeveloped Lands (91)",
                    YValue: UndevelopedLands91Acres,
                    HoverValue: UndevelopedLands91Acres / AllLandUseAcres,
                });

                this.isLoadingLandUseChartData = false;
            });
        }
    }

    public createSalmonDistributionChartData() {
        this.isLoadingSalmonDistributionChartData = true;
        if (this.userFilterObject && this.reachSearchResult) {
            this.userFilterObjectService.userFilterObjectsUserFilterObjectIDSalmonBearingMilesGet(this.userFilterObject.UserFilterObjectID).subscribe((result) => {
                this.salmonDistributionChartData = [];
                const MilesOfStream = this.sumPipe.transform(this.reachSearchResult.Reaches, "StreamMiles");
                const ChinookSalmonDocumentedMiles = this.sumPipe.transform(result, "ChinookSalmonDocumentedMiles");
                const ChinookSalmonPresumedMiles = this.sumPipe.transform(result, "ChinookSalmonPresumedMiles");
                const ChinookSalmonGradientAccessibleMiles = this.sumPipe.transform(result, "ChinookSalmonGradientAccessibleMiles");
                const ChumSalmonDocumentedMiles = this.sumPipe.transform(result, "ChumSalmonDocumentedMiles");
                const ChumSalmonPresumedMiles = this.sumPipe.transform(result, "ChumSalmonPresumedMiles");
                const ChumSalmonGradientAccessibleMiles = this.sumPipe.transform(result, "ChumSalmonGradientAccessibleMiles");
                const CohoSalmonDocumentedMiles = this.sumPipe.transform(result, "CohoSalmonDocumentedMiles");
                const CohoSalmonPresumedMiles = this.sumPipe.transform(result, "CohoSalmonPresumedMiles");
                const CohoSalmonGradientAccessibleMiles = this.sumPipe.transform(result, "CohoSalmonGradientAccessibleMiles");
                const KokaneeSalmonDocumentedMiles = this.sumPipe.transform(result, "KokaneeSalmonDocumentedMiles");
                const KokaneeSalmonPresumedMiles = this.sumPipe.transform(result, "KokaneeSalmonPresumedMiles");
                const KokaneeSalmonGradientAccessibleMiles = this.sumPipe.transform(result, "KokaneeSalmonGradientAccessibleMiles");
                const PinkSalmonDocumentedMiles = this.sumPipe.transform(result, "PinkSalmonDocumentedMiles");
                const PinkSalmonPresumedMiles = this.sumPipe.transform(result, "PinkSalmonPresumedMiles");
                const PinkSalmonGradientAccessibleMiles = this.sumPipe.transform(result, "PinkSalmonGradientAccessibleMiles");
                const SockeyeSalmonDocumentedMiles = this.sumPipe.transform(result, "SockeyeSalmonDocumentedMiles");
                const SockeyeSalmonPresumedMiles = this.sumPipe.transform(result, "SockeyeSalmonPresumedMiles");
                const SockeyeSalmonGradientAccessibleMiles = this.sumPipe.transform(result, "SockeyeSalmonGradientAccessibleMiles");
                const SteelheadTroutDocumentedMiles = this.sumPipe.transform(result, "SteelheadTroutDocumentedMiles");
                const SteelheadTroutPresumedMiles = this.sumPipe.transform(result, "SteelheadTroutPresumedMiles");
                const SteelheadTroutGradientAccessibleMiles = this.sumPipe.transform(result, "SteelheadTroutGradientAccessibleMiles");

                this.salmonDistributionChartData.push({
                    XValue: "Coho Salmon",
                    Group: "Documented",
                    YValue: CohoSalmonDocumentedMiles,
                    SortOrder: 11,
                    HoverValue: CohoSalmonDocumentedMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Coho Salmon",
                    Group: "Presumed",
                    YValue: CohoSalmonPresumedMiles,
                    SortOrder: 12,
                    HoverValue: CohoSalmonPresumedMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Coho Salmon",
                    Group: "Gradient Accessible",
                    YValue: CohoSalmonGradientAccessibleMiles,
                    SortOrder: 13,
                    HoverValue: CohoSalmonGradientAccessibleMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Chinook Salmon",
                    Group: "Documented",
                    YValue: ChinookSalmonDocumentedMiles,
                    SortOrder: 14,
                    HoverValue: ChinookSalmonDocumentedMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Chinook Salmon",
                    Group: "Presumed",
                    YValue: ChinookSalmonPresumedMiles,
                    SortOrder: 15,
                    HoverValue: ChinookSalmonPresumedMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Chinook Salmon",
                    Group: "Gradient Accessible",
                    YValue: ChinookSalmonGradientAccessibleMiles,
                    SortOrder: 16,
                    HoverValue: ChinookSalmonGradientAccessibleMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Pink Salmon",
                    Group: "Documented",
                    YValue: PinkSalmonDocumentedMiles,
                    SortOrder: 17,
                    HoverValue: PinkSalmonDocumentedMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Pink Salmon",
                    Group: "Presumed",
                    YValue: PinkSalmonPresumedMiles,
                    SortOrder: 18,
                    HoverValue: PinkSalmonPresumedMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Pink Salmon",
                    Group: "Gradient Accessible",
                    YValue: PinkSalmonGradientAccessibleMiles,
                    SortOrder: 19,
                    HoverValue: PinkSalmonGradientAccessibleMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Chum Salmon",
                    Group: "Documented",
                    YValue: ChumSalmonDocumentedMiles,
                    SortOrder: 20,
                    HoverValue: ChumSalmonDocumentedMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Chum Salmon",
                    Group: "Presumed",
                    YValue: ChumSalmonPresumedMiles,
                    SortOrder: 21,
                    HoverValue: ChumSalmonPresumedMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Chum Salmon",
                    Group: "Gradient Accessible",
                    YValue: ChumSalmonGradientAccessibleMiles,
                    SortOrder: 22,
                    HoverValue: ChumSalmonGradientAccessibleMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Steelhead Trout",
                    Group: "Documented",
                    YValue: SteelheadTroutDocumentedMiles,
                    SortOrder: 23,
                    HoverValue: SteelheadTroutDocumentedMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Steelhead Trout",
                    Group: "Presumed",
                    YValue: SteelheadTroutPresumedMiles,
                    SortOrder: 24,
                    HoverValue: SteelheadTroutPresumedMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Steelhead Trout",
                    Group: "Gradient Accessible",
                    YValue: SteelheadTroutGradientAccessibleMiles,
                    SortOrder: 25,
                    HoverValue: SteelheadTroutGradientAccessibleMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Sockeye Salmon",
                    Group: "Documented",
                    YValue: SockeyeSalmonDocumentedMiles,
                    SortOrder: 26,
                    HoverValue: SockeyeSalmonDocumentedMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Sockeye Salmon",
                    Group: "Presumed",
                    YValue: SockeyeSalmonPresumedMiles,
                    SortOrder: 27,
                    HoverValue: SockeyeSalmonPresumedMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Sockeye Salmon",
                    Group: "Gradient Accessible",
                    YValue: SockeyeSalmonGradientAccessibleMiles,
                    SortOrder: 28,
                    HoverValue: SockeyeSalmonGradientAccessibleMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Kokanee Salmon",
                    Group: "Documented",
                    YValue: KokaneeSalmonDocumentedMiles,
                    SortOrder: 29,
                    HoverValue: KokaneeSalmonDocumentedMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Kokanee Salmon",
                    Group: "Presumed",
                    YValue: KokaneeSalmonPresumedMiles,
                    SortOrder: 30,
                    HoverValue: KokaneeSalmonPresumedMiles / MilesOfStream,
                });
                this.salmonDistributionChartData.push({
                    XValue: "Kokanee Salmon",
                    Group: "Gradient Accessible",
                    YValue: KokaneeSalmonGradientAccessibleMiles,
                    SortOrder: 31,
                    HoverValue: KokaneeSalmonGradientAccessibleMiles / MilesOfStream,
                });
                this.isLoadingSalmonDistributionChartData = false;
            });
        }
    }

    public createZoneAreaChartData() {
        if (this.userFilterObject && this.reachSearchResult) {
            const totalAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "ReachAcres");
            const eowAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "EOWAcres");
            const rmzAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "RMZAcres");
            const floodAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "FloodAcres");

            this.zoneAreaChartData = [];
            this.zoneAreaChartData.push({
                XValue: "Riparian Management Zone",
                YValue: rmzAcres,
                HoverValue: rmzAcres / totalAcres,
            });
            this.zoneAreaChartData.push({
                XValue: "Extent of Observed Water",
                YValue: eowAcres,
                HoverValue: eowAcres / totalAcres,
            });
            this.zoneAreaChartData.push({
                XValue: "Floodplain",
                YValue: floodAcres,
                SortOrder: 13,
                HoverValue: floodAcres / totalAcres,
            });
        }
    }
}

export interface ReachExplorerEntityDto {
    EntityID: number;
    EntityName: string;
    TotalAcres?: number;
    HasData?: boolean;
    RiparianAcres?: number;
    StreamMiles?: number;
    EntityIDAndName: string;
}
