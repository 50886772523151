import { Component, OnInit, HostListener, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { environment } from "src/environments/environment";
import { UserDto } from "../../generated/model/user-dto";
import { FlagEnum } from "../../generated/enum/flag-enum";
import { RoleEnum } from "../../generated/enum/role-enum";
import { PermissionEnum } from "../../generated/enum/permission-enum";
import { RightsEnum } from "../../models/enums/rights.enum";
import { Observable, tap } from "rxjs";
import { WithRolePermissionDirective } from "../../directives/with-role-permission.directive";
import { DropdownToggleDirective } from "../../directives/dropdown-toggle.directive";
import { IconComponent } from "src/app/shared/components/icon/icon.component";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { DropdownToggleCloseDirective } from "../../directives/dropdown-toggle-close.directive";
import { NgIf, AsyncPipe } from "@angular/common";

@Component({
    selector: "header-nav",
    templateUrl: "./header-nav.component.html",
    styleUrls: ["./header-nav.component.scss"],
    standalone: true,
    imports: [NgIf, DropdownToggleCloseDirective, RouterLink, RouterLinkActive, IconComponent, DropdownToggleDirective, WithRolePermissionDirective, AsyncPipe],
})
export class HeaderNavComponent implements OnInit, OnDestroy {
    readonly RoleEnum = RoleEnum;
    public FlagEnum = FlagEnum;
    currentUser: UserDto;
    currentUser$: Observable<UserDto>;

    public windowWidth: number;

    public PermissionEnum = PermissionEnum;
    public RightsEnum = RightsEnum;

    @HostListener("window:resize", ["$event"])
    resize() {
        this.windowWidth = window.innerWidth;
    }

    constructor(private authenticationService: AuthenticationService, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.currentUser$ = this.authenticationService.getCurrentUser().pipe(
            tap((currentUser) => {
                this.currentUser = currentUser;
            })
        );
    }

    ngOnDestroy() {
        this.cdr.detach();
    }

    public isAuthenticated(): boolean {
        return this.authenticationService.isAuthenticated();
    }

    public getUserName() {
        return this.currentUser ? this.currentUser.FullName : null;
    }

    public login(): void {
        this.authenticationService.login();
    }

    public logout(): void {
        this.authenticationService.logout();

        setTimeout(() => {
            this.cdr.detectChanges();
        });
    }

    public showTestingWarning(): boolean {
        return environment.staging || environment.dev;
    }

    public testingWarningText(): string {
        return environment.staging ? "QA Environment" : "Development Environment";
    }

    public isCurrentUserBeingImpersonated(): boolean {
        return this.authenticationService.isCurrentUserBeingImpersonated(this.currentUser);
    }

    public hasManageMenu(): boolean {
        const hasMenu = this.authenticationService.hasFlag(this.currentUser, FlagEnum.HasManagerDashboard);
        return hasMenu;
    }
}
